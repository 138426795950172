var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('location'),_c('div',{staticClass:"page wrap-1200",class:[{ p30: _vm.tab === 1 }, { p31: _vm.tab === 2 }]},[_c('swiper',{staticClass:"swiper mySwiper",attrs:{"options":{
          slidesPerView: 'auto',
          freeMode: true,
          initialSlide: _vm.tab - 1,
        }}},[_c('swiper-slide',{class:{ active: _vm.tab === 1 }},[_c('div',{on:{"click":function($event){return _vm.tab_change(1)}}},[_vm._v("토너먼트 요강")])]),_c('swiper-slide',{class:{ active: _vm.tab === 2 }},[_c('div',{on:{"click":function($event){return _vm.tab_change(2)}}},[_vm._v("토너먼트 순위")])])],1),_c('ul',{staticClass:"nav nav-pills wrap-1200",attrs:{"id":"pills-tab","role":"tablist"}},[_c('li',{staticClass:"nav-item col-6",attrs:{"role":"presentation"}},[_c('button',{staticClass:"w-100 nav-link",class:{ active: _vm.tab == 1 },attrs:{"id":"pills-1-tab","type":"button","role":"tab","aria-controls":"pills-1","aria-selected":"true"},on:{"click":function($event){return _vm.tab_change(1)}}},[_vm._v(" 토너먼트 요강 ")])]),_c('li',{staticClass:"nav-item col-6",attrs:{"role":"presentation"}},[_c('button',{staticClass:"w-100 nav-link",class:{ active: _vm.tab === 2 },attrs:{"id":"pills-2-tab","type":"button","role":"tab","aria-controls":"pills-2","aria-selected":"false"},on:{"click":function($event){return _vm.tab_change(2)}}},[_vm._v(" 토너먼트 순위 ")])])]),_c('div',{staticClass:"tab-content",class:{ 'wrap-1200': _vm.tab === 1 },attrs:{"id":"pills-tabContent"}},[_c('Router-View')],1),_c('div',{staticClass:"btn-bottom-wrapper"},[_c('b-button',{attrs:{"size":"xl","variant":"outline-primary","aria-label":"목록"},on:{"click":function($event){_vm.$router
                .push({
                  name: 'TournamentSchedule',
                  query: {
                    searchtype: _vm.$route.query.searchtype,
                    searchname: _vm.$route.query.searchname,
                  },
                })
                .catch(function () {})}}},[_vm._v("목록")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }