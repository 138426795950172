<template>
  <div>
    <location />

    <div class="p29 page">
      <b-form @submit="onSubmit">
        <div class="wrap-1200">
          <div class="select-search list">
            <select
              class=""
              aria-label=".form-select-lg example"
              name="searchtype"
              v-model="searchData.searchtype"
            >
              <option value="1">대회명</option>
              <option value="2">매장명</option>
              <option value="3">코스명</option>
            </select>
            <input
              type="text"
              class="form-control-lg"
              name="searchname"
              v-model="searchData.searchname"
            />
            <input
              class="btn btn-primary btn-lg search"
              type="submit"
              value=""
            />
          </div>
        </div>
      </b-form>

      <table
        class="board table wrap-1200"
        id="dataTable"
        width="100%"
        cellspacing="0"
      >
        <thead>
          <tr>
            <th class="col-auto d-lg-table-cell">대회명</th>
            <th class="col-auto d-none d-lg-table-cell">유형</th>
            <th class="col-auto d-none d-lg-table-cell">기간</th>
            <th class="col-auto d-none d-lg-table-cell">코스</th>
            <th class="col-auto d-lg-table-cell text-center">매장</th>
            <!-- <th class="col-auto d-none d-lg-table-cell">Point</th> -->
            <th class="col-auto d-none d-lg-table-cell">상태</th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in items">
            <tr class="btn1" @click="toggle_tr(index)" :key="index">
              <td class="col-auto d-lg-table-cell">
                <router-link
                  :to="{
                    name: 'Condition',
                    params: { id: item.tournamentinfo.tournamentno },
                    query: {
                      searchtype: searchData.searchtype,
                      searchname: searchData.searchname,
                    },
                  }"
                  class="more"
                >
                  {{ item.tournamentinfo.title }}
                </router-link>
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.tournamentinfo.type == 1 ? "공개" : "비공개" }}
              </td>
              <td class="col-auto d-none d-lg-table-cell text-date">
                {{
                  $moment(item.tournamentinfo.startdate).format("YYYY-MM-DD") +
                  "~" +
                  $moment(item.tournamentinfo.enddate).format("YYYY-MM-DD")
                }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                <template v-for="(coures, c_index) in item.tournamentcourse">
                  <p :key="c_index">{{ coures.coursename }}</p>
                </template>
              </td>
              <td class="col-auto d-lg-table-cell">
                <template v-for="(shop, s_index) in item.tournamentshop">
                  <p :key="s_index">{{ shop.shopname }}</p>
                </template>
              </td>
              <!-- <td class="col-auto d-none d-lg-table-cell text-cyan">
                {{ item.tournamentinfo.point | comma }}p
              </td> -->
              <td class="col-auto d-none d-lg-table-cell">
                {{ tournament_status(item.tournamentinfo.status) }}
              </td>
              <td class="td-arrow">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="col-auto d-lg-none d-md-tabel-cell"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td class="align-left bg-white" colspan="6">
                <!--
                <router-link
                  :to="{
                    name: 'Condition',
                    params: { id: item.tournamentinfo.tournamentno },
                    query: {
                      searchtype: searchData.searchtype,
                      searchname: searchData.searchname
                    }
                  }"
                  class="btn1 btn_view"
                >
                  <img src="/img/ico_edit.png" />
                </router-link>
                -->
                <div class="layer1">
                  <!-- <div class="row g-0">
                    <div class="col-6">No.</div>
                    <div class="col-6">{{ item.tournamentinfo.no }}</div>
                  </div> -->
                  <div class="row g-0">
                    <div class="col-2">유형</div>
                    <div class="col">
                      {{ item.tournamentinfo.type == 1 ? "open" : "secret" }}
                    </div>
                  </div>
                  <div class="row g-0">
                    <div class="col-2">기간</div>
                    <div class="col date">
                      {{
                        $moment(item.tournamentinfo.startdate).format(
                          "YYYY-MM-DD"
                        ) +
                        "~" +
                        $moment(item.tournamentinfo.enddate).format(
                          "YYYY-MM-DD"
                        )
                      }}
                    </div>
                  </div>
                  <div class="row g-0">
                    <div class="col-2">코스</div>
                    <div class="col">
                      <template
                        v-for="(coures, c_index) in item.tournamentcourse"
                      >
                        <p :key="c_index">{{ coures.coursename }}</p>
                      </template>
                    </div>
                  </div>
                  <!-- <div class="row g-0">
                    <div class="col-6">Point</div>
                    <div class="col-6 text-cyan">
                      {{ item.tournamentinfo.point | comma }}p
                    </div>
                  </div> -->
                  <div class="row g-0">
                    <div class="col-2">상태</div>
                    <div class="col">
                      {{ tournament_status(item.tournamentinfo.status) }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">데이터가 없습니다.</td>
          </tr>
        </tbody>
      </table>

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation example"
        class="mt-3"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/tournament";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "TournamentSchedule",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        searchname: this.$route.query.searchname || "",
        searchtype: parseInt(this.$route.query.searchtype) || 1,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
    };
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_tournamentlist();
    },
  },
  methods: {
    get_tournamentlist() {
      ax.get_tournamentlist(this.searchData, (flag, data) => {
        if (flag) {
          if (data.tournamentlistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.tournamentlistcnt;
          this.page_total = Math.ceil(data.tournamentlistcnt / this.rows);
          this.items = data.tournamentlist;
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            searchname: this.searchData.searchname,
            searchtype: this.searchData.searchtype,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? `?searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}`
        : `?page=${pageNum}&searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_tournamentlist();
  },
  mixins: [myMixin],
};
</script>
