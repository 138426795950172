<template>
  <div>
    <locations />

    <div class="page wrap-1200">
      <h3 class="subtitle">피팅 정보 입력</h3>
      <b-form @submit="onSubmit">
        <div class="bp-w440-lt">
          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >신장<small class="d-inline font-monospace fw-400"
                    >(cm)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <input
                type="text"
                placeholder="단위 생략"
                v-model="form.fit_user_height"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >바닥에서 손목높이<small
                    class="d-inline font-monospace fw-400"
                    >(cm)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <input
                type="text"
                placeholder="단위 생략"
                v-model="form.fit_user_wrist"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >몸무게<small class="d-inline font-monospace fw-400"
                    >(kg)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <input
                type="text"
                placeholder="단위 생략"
                v-model="form.fit_user_weight"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell"
                ><span class="required">핸디캡</span></label
              >
            </div>
            <div class="col-lg-9 d-table form-check-group">
              <div class="d-table-cell align-middle">
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="1"
                    id="handicap1"
                    v-model="form.fit_user_handicap"
                  />
                  <label class="form-check-label" for="handicap1">-79 </label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="2"
                    id="handicap2"
                    v-model="form.fit_user_handicap"
                  />
                  <label class="form-check-label" for="handicap2">80-89</label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="3"
                    id="handicap3"
                    v-model="form.fit_user_handicap"
                  />
                  <label class="form-check-label" for="handicap3">90-99</label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="4"
                    id="handicap4"
                    v-model="form.fit_user_handicap"
                  />
                  <label class="form-check-label" for="handicap4">+100</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >평균드라이버거리<small class="d-inline font-monospace fw-400"
                    >(m)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9 d-table form-check-group">
              <div class="d-table-cell align-middle">
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="1"
                    id="driver1"
                    v-model="form.fit_user_driver"
                  />
                  <label class="form-check-label" for="driver1">+240 </label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="2"
                    id="driver2"
                    v-model="form.fit_user_driver"
                  />
                  <label class="form-check-label" for="driver2">220-240 </label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="3"
                    id="driver3"
                    v-model="form.fit_user_driver"
                  />
                  <label class="form-check-label" for="driver3">
                    200-220
                  </label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="4"
                    id="driver4"
                    v-model="form.fit_user_driver"
                  />
                  <label class="form-check-label" for="driver4">
                    180-200
                  </label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="5"
                    id="driver5"
                    v-model="form.fit_user_driver"
                  />
                  <label class="form-check-label" for="driver5"> -179 </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required">클럽의 중요성</span>
              </label>
            </div>
            <div class="col-lg-9 d-table form-check-group">
              <div class="d-table-cell align-middle">
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="1"
                    id="important1"
                    v-model="form.fit_user_important"
                  />
                  <label class="form-check-label" for="important1">
                    타구감
                  </label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="2"
                    id="important2"
                    v-model="form.fit_user_important"
                  />
                  <label class="form-check-label" for="important2">방향</label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="3"
                    id="important3"
                    v-model="form.fit_user_important"
                  />
                  <label class="form-check-label" for="important3">거리</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required">선호 샤프트</span>
              </label>
            </div>
            <div class="col-lg-9">
              <select aria-label="강도선택" v-model="form.fit_user_use_shaft">
                <option value="">샤프트 강도</option>
                <option
                  v-for="(val, idx) in ['R', 'SR', 'S', 'L']"
                  :key="'fit_user_use_shaft' + val"
                  :value="idx + 1"
                >
                  {{ val }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >손 사이즈<small class="d-inline font-monospace fw-400"
                    >(cm)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <div class="row">
                <div class="col-sm-6 gx-0 gx-sm-2 ps-0">
                  <select
                    name="fieldunit"
                    id="fieldunit"
                    v-model="form.fit_user_glove"
                    requried
                  >
                    <option value="">손 길이</option>
                    <option
                      v-for="(val, idx) in Array.from(
                        { length: 15 },
                        (x, i) => i + 14
                      )"
                      :key="'fit_user_glove' + idx"
                      :value="val"
                    >
                      {{ val }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-6 gx-0 gx-sm-2 pe-0 mt-2 mt-sm-0">
                  <select v-model="form.fit_user_finger" requried>
                    <option value="">중지 길이</option>
                    <option
                      v-for="(val, idx) in Array.from(
                        { length: 15 },
                        (x, i) => i + 0.5
                      )"
                      :key="'fit_user_finger' + idx"
                      :value="val"
                    >
                      {{ val }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >가슴 둘레<small class="d-inline font-monospace fw-400"
                    >(cm)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <input
                type="text"
                placeholder="단위 생략"
                v-model="form.fit_user_shirt"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >허리 둘레<small class="d-inline font-monospace fw-400"
                    >(Inch)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <input
                type="text"
                placeholder="단위 생략"
                v-model="form.fit_user_pants"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >신발 사이즈<small class="d-inline font-monospace fw-400"
                    >(mm)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <input
                type="text"
                placeholder="단위 생략"
                v-model="form.fit_user_shoes"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required">나의 문제점</span>
              </label>
            </div>
            <div class="col-lg-9">
              <textarea
                placeholder=""
                cols="30"
                rows="3"
                v-model="form.fit_user_trouble"
              ></textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span>보유장비</span>
              </label>
            </div>
            <div class="col-lg-9 m-0 p-0">
              <div class="row border-bottom">
                <div class="col-lg-3 d-table border-bottom border-lg-0">
                  <label class="d-table-cell">
                    <span class="text-gray">드라이버</span>
                  </label>
                </div>
                <div class="col-lg-9 d-table">
                  <div class="row">
                    <div class="col-sm-6 gx-0 gx-sm-2 ps-0">
                      <input
                        type="text"
                        placeholder=""
                        v-model="form.fit_user_use_driver"
                        required
                      />
                      <!-- <select>
                        <option>브랜드</option>
                        <option>타이틀리스트</option>
                      </select> -->
                    </div>
                    <div class="col-sm-6 gx-0 gx-sm-2 pe-0 mt-2 mt-sm-0">
                      <select v-model="form.fit_user_use_driver_date" requried>
                        <option value="" disabled>구입년도</option>
                        <option
                          v-for="(val, idx) in Array.from(
                            { length: 20 },
                            (x, i) => $moment(new Date()).format('YYYY') - i
                          )"
                          :key="'fit_user_use_driver_date' + idx"
                          :value="val"
                        >
                          {{ val }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row border-bottom">
                <div class="col-lg-3 d-table border-bottom border-lg-0">
                  <label class="d-table-cell">
                    <span class="text-gray">우드</span>
                  </label>
                </div>
                <div class="col-lg-9 d-table">
                  <div class="row">
                    <div class="col-sm-6 gx-0 gx-sm-2 ps-0">
                      <input
                        type="text"
                        placeholder=""
                        v-model="form.fit_user_use_wood"
                        required
                      />
                    </div>
                    <div class="col-sm-6 gx-0 gx-sm-2 pe-0 mt-2 mt-sm-0">
                      <select v-model="form.fit_user_use_wood_date" requried>
                        <option value="" disabled>구입년도</option>
                        <option
                          v-for="(val, idx) in Array.from(
                            { length: 20 },
                            (x, i) => $moment(new Date()).format('YYYY') - i
                          )"
                          :key="'fit_user_use_wood_date' + idx"
                          :value="val"
                        >
                          {{ val }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row border-bottom">
                <div class="col-lg-3 d-table border-bottom border-lg-0">
                  <label class="d-table-cell">
                    <span class="text-gray">아이언</span>
                  </label>
                </div>
                <div class="col-lg-9 d-table">
                  <div class="row">
                    <div class="col-sm-6 gx-0 gx-sm-2 ps-0">
                      <input
                        type="text"
                        placeholder=""
                        v-model="form.fit_user_use_iron"
                        required
                      />
                    </div>
                    <div class="col-sm-6 gx-0 gx-sm-2 pe-0 mt-2 mt-sm-0">
                      <select v-model="form.fit_user_use_iron_date" requried>
                        <option value="" disabled>구입년도</option>
                        <option
                          v-for="(val, idx) in Array.from(
                            { length: 20 },
                            (x, i) => $moment(new Date()).format('YYYY') - i
                          )"
                          :key="'fit_user_use_iron_date' + idx"
                          :value="val"
                        >
                          {{ val }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 d-table border-bottom border-lg-0">
                  <label class="d-table-cell">
                    <span class="text-gray">퍼터</span>
                  </label>
                </div>
                <div class="col-lg-9 d-table">
                  <div class="row">
                    <div class="col-sm-6 gx-0 gx-sm-2 ps-0">
                      <input
                        type="text"
                        placeholder=""
                        v-model="form.fit_user_use_putter"
                        required
                      />
                    </div>
                    <div class="col-sm-6 gx-0 gx-sm-2 pe-0 mt-2 mt-sm-0">
                      <select v-model="form.fit_user_use_putter_date" requried>
                        <option value="" disabled>구입년도</option>
                        <option
                          v-for="(val, idx) in Array.from(
                            { length: 20 },
                            (x, i) => $moment(new Date()).format('YYYY') - i
                          )"
                          :key="'fit_user_use_putter_date' + idx"
                          :value="val"
                        >
                          {{ val }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span>진행여부 확인</span>
              </label>
            </div>
            <div class="col-lg-9 d-table d-flex align-items-center">
              <div class="d-flex align-items-center">
                <input
                  class="form-check-input flex-shrink-0"
                  type="checkbox"
                  true-value="1"
                  false-value="0"
                  id="email_cd"
                  v-model="form.email_cd"
                />
                <label for="email_cd" class="pointer text-black"
                  >커스텀피팅 진행사항을 SMS나 E-mail로 받아
                  보시겠습니까?</label
                >
              </div>
            </div>
          </div>

          <div class="agree_box px-0 px-sm-3">
            <div class="form-check">
              <input
                type="checkbox"
                id="agree"
                class="form-check-input custom-checkbox"
                v-model="form.agree"
              />
              <label class="form-check-label text-gray" for="agree">
                회원님의 정확한 커스텀피팅을 위해 개인정보와 라운딩리포트를
                브랜드사에게 수집, 이용하는 것에 동의 합니다
              </label>
            </div>
          </div>

          <div class="btn-bottom-wrapper">
            <b-button
              size="xl"
              variant="outline-primary"
              aria-label="취소"
              @click="
                $router.push({
                  name: 'FittingSwingList',
                })
              "
              >취소</b-button
            >
            <b-button
              type="submit"
              size="xl"
              variant="secondary"
              aria-label="등록"
              >등록</b-button
            >
            <b-button
              type="submit"
              size="xl"
              variant="primary"
              aria-label="알고리즘 추천 받기"
              @click="form.is_algorithm = true"
              >알고리즘 추천 받기</b-button
            >
          </div>
        </div>
        <!-- //bp-w440-lt -->
      </b-form>
    </div>
  </div>
</template>

<script>
import locations from "@/components/Location";
import ax from "@/api/fitting";
import "@/filter/common";
import _ from "lodash";
export default {
  name: "FittingRequestWrite",
  data() {
    return {
      form: {
        fit_user_height: "",
        fit_user_wrist: "",
        fit_user_weight: "",
        fit_user_handicap: "",
        fit_user_driver: "",
        fit_user_important: "",
        fit_user_use_shaft: "",
        fit_user_glove: "",
        fit_user_finger: "",
        fit_user_shirt: "",
        fit_user_pants: "",
        fit_user_shoes: "",
        fit_user_trouble: "",
        fit_user_use_driver: "",
        fit_user_use_driver_date: "",
        fit_user_use_wood: "",
        fit_user_use_wood_date: "",
        fit_user_use_iron: "",
        fit_user_use_iron_date: "",
        fit_user_use_putter: "",
        fit_user_use_putter_date: "",
        sms_cd: "0",
        email_cd: "0",
        is_algorithm: false,
      },
      fittingno: this.$route.params.fittingno || 0,
      fittingreqno: this.$route.params.fittingreqno || 0,
    };
  },
  components: {
    locations,
  },

  created() {
    this.get_fittingreqinfo();
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      ax.post_fittingrequestwrite(this.form, this.fittingno, (flag, data) => {
        if (flag) {
          alert(data.message);

          this.$router.push({
            name: "FittingSwingList",
          });
        } else {
          alert(data.message);
          return false;
        }
      });
    },

    get_fittingreqinfo() {
      {
        ax.get_fittingreqinfo((flag, data) => {
          if (flag) {
            for (let key in this.form) {
              if (data.fittingreqinfo[key])
                this.form[key] = data.fittingreqinfo[key];
            }

            this.form.fit_user_use_driver_date = this.$moment(
              data.fittingreqinfo.fit_user_use_driver_date
            ).format("YYYY");
            this.form.fit_user_use_wood_date = this.$moment(
              data.fittingreqinfo.fit_user_use_wood_date
            ).format("YYYY");
            this.form.fit_user_use_iron_date = this.$moment(
              data.fittingreqinfo.fit_user_use_iron_date
            ).format("YYYY");
            this.form.fit_user_use_putter_date = this.$moment(
              data.fittingreqinfo.fit_user_use_putter_date
            ).format("YYYY");

            console.log("this.form", this.form);
          } else {
            alert(data.message);
          }
        });
      }
    },
  },
};
</script>
