<template>
  <div>
    <locations />

    <div id="brs" class="page wrap-1200">
      <h3 class="subtitle">피팅 내역</h3>
      <section class="adv mt-0">
        <!-- <h6 class="sub-title">피팅 광고</h6> -->
        <div class="row g-0">
          <div class="col-md-6">
            <div
              class="col adv-item adv-video"
              :class="{
                'has-item': fittingreqinfo.fit_ad_no_mov,
              }"
            >
              <video
                class="ratio align-self-center"
                controls
                autoplay
                muted
                v-if="fittingreqinfo.adinfo.ad_mov_info.fit_ad_mov"
              >
                <source
                  v-if="fittingreqinfo.adinfo.ad_mov_info.fit_ad_mov"
                  type="video/mp4"
                  :src="fittingreqinfo.adinfo.ad_mov_info.fit_ad_mov | get_img"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div class="col-md-6 flex-wrap">
            <div
              class="col-12 adv-item adv-title justify-content-center mb-2"
              :class="{ 'has-item': fittingreqinfo.fit_ad_no_title }"
            >
              <img
                v-if="fittingreqinfo.fit_ad_no_title"
                class="align-self-center"
                :src="fittingreqinfo.adinfo.ad_title_info.fit_ad_img | get_img"
              />
            </div>
            <div class="row g-0 flex-grow-1">
              <div
                class="col adv-item adv-goods flex-sm-wrap"
                :class="{ 'has-item': fittingreqinfo.fit_ad_no_img1 }"
              >
                <img
                  v-if="fittingreqinfo.fit_ad_no_img1"
                  :src="fittingreqinfo.adinfo.ad_img1_info.fit_ad_img | get_img"
                />
                <p class="goods-name" v-if="fittingreqinfo.fit_ad_no_img1">
                  <span>{{
                    fittingreqinfo.adinfo.ad_img1_info.fit_ad_title
                  }}</span>
                </p>
              </div>

              <div
                class="col adv-item adv-goods mx-0 mx-sm-2 flex-sm-wrap"
                :class="{ 'has-item': fittingreqinfo.fit_ad_no_img2 }"
              >
                <img
                  v-if="fittingreqinfo.fit_ad_no_img2"
                  :src="fittingreqinfo.adinfo.ad_img2_info.fit_ad_img | get_img"
                />
                <p class="goods-name" v-if="fittingreqinfo.fit_ad_no_img2">
                  <span>{{
                    fittingreqinfo.adinfo.ad_img2_info.fit_ad_title
                  }}</span>
                </p>
              </div>
              <div
                class="col adv-item adv-goods flex-sm-wrap"
                :class="{ 'has-item': fittingreqinfo.fit_ad_no_img3 }"
              >
                <img
                  v-if="fittingreqinfo.fit_ad_no_img3"
                  :src="fittingreqinfo.adinfo.ad_img3_info.fit_ad_img | get_img"
                />
                <p class="goods-name" v-if="fittingreqinfo.fit_ad_no_img3">
                  <span>{{
                    fittingreqinfo.adinfo.ad_img3_info.fit_ad_title
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- //광고등록 -->

      <div class="tab-sub mt-4">
        <swiper
          :options="{
            slidesPerView: 'auto',
            freeMode: true,
            loop: false,
            initialSlide: preViewTabIndex,
          }"
        >
          <swiper-slide
            :class="{
              active: preViewTabIndex == 0,
            }"
          >
            <div @click="preViewTabIndex = 0">피팅리포트</div>
          </swiper-slide>
          <swiper-slide :class="{ active: preViewTabIndex == 1 }">
            <div @click="preViewTabIndex = 1">원포인트레슨</div>
          </swiper-slide>
          <swiper-slide :class="{ active: preViewTabIndex == 2 }">
            <div @click="preViewTabIndex = 2">고급형</div>
          </swiper-slide>
          <swiper-slide :class="{ active: preViewTabIndex == 3 }">
            <div @click="preViewTabIndex = 3">보급형</div>
          </swiper-slide>
        </swiper>
      </div>
      <!-- //tab-sub -->

      <div class="tab-content" id="pills-tabContent">
        <!-- TabIndex : 0, 피팅리포트 -->
        <div
          class="tab-pane fade show"
          :class="{ active: preViewTabIndex == 0 }"
        >
          <section>
            <h6 class="sub-title">피팅 종합 분석</h6>
            <div class="row g-0 table-type header-col">
              <div class="col-sm-5 col-md-4">
                <span>피팅 분석평</span>
              </div>
              <div class="col-sm-7 col-md-8">
                <textarea
                  class="form-control"
                  v-model="fittingreqinfo.fit_ans_txt"
                  readonly
                ></textarea>
              </div>
              <div class="col-sm-5 col-md-4 border-sm-0">
                <span>첨부파일</span>
              </div>
              <div class="col-sm-7 col-md-8">
                <p v-if="fittingreqinfo.fit_ans_file"
                  class="text-subscript text-truncate more"
                >
                  {{ fittingreqinfo.fit_ans_file }}
                </p>
              </div>
            </div>
          </section>
          <!-- //피팅 종합 분석 -->

          <section class="fitting-anls">
            <h6 class="sub-title">
              구질 및 클럽 분석
              <mark class="font-monospace text-orange"
                >({{ fittingreqinfo.fit_club_type | get_club_type }} 기준)</mark
              >
            </h6>
            <div class="card-group">
              <div class="row g-3 flex-fill">
                <!-- 공통 : 구질분석 -->
                <div class="col-md-4">
                  <div class="card h-100">
                    <div class="card-body">
                      <Radar
                        class="pitch-anls"
                        :chart-data="resultradardata"
                        :chart-options="radaroptions"
                      />
                    </div>
                    <!-- //card-body -->
                    <div
                      class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                    >
                      <div class="row gx-2 gy-2 text-nowrap fs-14">
                        <div class="col">
                          <div class="d-flex flex-column">
                            <p class="mb-2">
                              <small class="rounded-circle bg-green">1</small
                              >거리
                            </p>
                            <input
                              type="text"
                              class="text-truncate"
                              aria-label="거리 평가"
                              :value="fittingreqinfo.grade_distance"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="col">
                          <div class="d-flex flex-column">
                            <p class="mb-2">
                              <small class="rounded-circle bg-orange">2</small
                              >방향성
                            </p>
                            <input
                              type="text"
                              class="text-truncate"
                              aria-label="방향성 평가"
                              v-model="fittingreqinfo.grade_direction"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="col">
                          <div class="d-flex flex-column">
                            <p class="mb-2">
                              <small class="rounded-circle bg-pink">3</small
                              >정확도
                            </p>
                            <input
                              type="text"
                              class="text-truncate"
                              aria-label="정확도 평가"
                              v-model="fittingreqinfo.grade_accuracy"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="col">
                          <div class="d-flex flex-column">
                            <p class="mb-2">
                              <small class="rounded-circle bg-purple">4</small
                              >자세
                            </p>
                            <input
                              type="text"
                              class="text-truncate"
                              aria-label="자세 평가"
                              v-model="fittingreqinfo.grade_position"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="col">
                          <div class="d-flex flex-column">
                            <p class="mb-2">
                              <small class="rounded-circle bg-blue">5</small
                              >임팩트
                            </p>
                            <input
                              type="text"
                              class="text-truncate"
                              aria-label="임팩트 평가"
                              v-model="fittingreqinfo.grade_impact"
                              readonly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- //card-footer -->
                  </div>
                </div>
                <!-- //공통 : 구질분석 -->
                <!-- 클럽분석 : 드라이버, 우드, 유틸 -->
                <div
                  class="col-md-4 border-end-md border-start-md"
                  v-if="fittingreqinfo.fit_club_type < 4"
                >
                  <div class="card h-100">
                    <div class="card-body d-flex justify-content-center p-0">
                      <img
                        src="/img/fit_anls_dvr_tv.png"
                        class="align-self-center img-fluid"
                        alt="클럽분석"
                      />
                    </div>
                    <!-- //card-body -->
                    <div
                      class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                    >
                      <div class="row gx-2 gy-2 text-nowrap fs-14">
                        <div class="col">
                          <div class="d-flex flex-column">
                            <p class="mb-2">
                              <small class="rounded-circle bg-green">1</small
                              >헤드볼륨
                            </p>
                            <p class="d-flex align-items-center">
                              <input
                                type="text"
                                class="text-truncate"
                                id="head-volume"
                                placeholder="헤드볼륨"
                                aria-label="헤드볼륨 입력"
                                v-model="fittingreqinfo.club_headvolume"
                                readonly
                              />
                              <label for="head-volume" class="ms-1">㎤</label>
                            </p>
                          </div>
                        </div>
                        <div class="col">
                          <div class="d-flex flex-column">
                            <p class="mb-2">
                              <small class="rounded-circle bg-orange">2</small
                              >로프트
                            </p>
                            <p class="d-flex align-items-center">
                              <input
                                type="text"
                                class="text-truncate"
                                id="loft"
                                placeholder="로프트"
                                aria-label="로프트 입력"
                                v-model="fittingreqinfo.club_loft"
                                readonly
                              />
                              <label for="loft" class="ms-1">&deg;</label>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- //card-footer -->
                  </div>
                </div>

                <div class="col-md-4" v-if="fittingreqinfo.fit_club_type < 4">
                  <div class="card h-100">
                    <div class="card-body d-flex justify-content-center p-0">
                      <img
                        src="/img/fit_anls_dvr_fv.png"
                        class="align-self-center img-fluid"
                        alt="클럽분석"
                      />
                    </div>
                    <!-- //card-body -->
                    <div
                      class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top"
                    >
                      <div class="row gx-2 gy-2 text-nowrap fs-14">
                        <div class="col">
                          <div class="d-flex flex-column">
                            <p class="mb-2">
                              <small class="rounded-circle bg-green">1</small
                              >라이
                            </p>
                            <p class="d-flex align-items-center">
                              <input
                                type="text"
                                class="text-truncate"
                                id="lie"
                                placeholder="라이"
                                aria-label="라이 입력"
                                v-model="fittingreqinfo.club_lie"
                                readonly
                              />
                              <label for="lie" class="ms-1">&deg;</label>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- //card-footer -->
                  </div>
                </div>
                <!-- //클럽분석 : 드라이버, 우드, 유틸 -->

                <!-- 클럽분석 : 아이언, 웨지 -->
                <div
                  class="col-md-4 border-end-md border-start-md"
                  v-if="fittingreqinfo.fit_club_type > 3"
                >
                  <div class="card h-100">
                    <div class="card-body d-flex justify-content-center p-0">
                      <img
                        src="/img/fit_anls_iron_tv.png"
                        class="align-self-center img-fluid"
                        alt="클럽분석"
                      />
                    </div>
                    <!-- //card-body -->
                    <div
                      class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top border-bottom-gray border-bottom-md-0"
                    >
                      <div class="row gx-2 gy-2 text-nowrap fs-14">
                        <div class="col">
                          <div class="d-flex flex-column">
                            <p class="mb-2">
                              <small class="rounded-circle bg-green">1</small
                              >바운스
                            </p>
                            <p class="d-flex align-items-center">
                              <input
                                type="text"
                                class="text-truncate"
                                id="bounce"
                                placeholder="바운스"
                                aria-label="바운스 입력"
                                v-model="fittingreqinfo.club_bounce"
                                readonly
                              />
                              <label for="bounce" class="ms-1">&deg;</label>
                            </p>
                          </div>
                        </div>
                        <div class="col">
                          <div class="d-flex flex-column">
                            <p class="mb-2">
                              <small class="rounded-circle bg-orange">2</small
                              >FP
                            </p>
                            <p class="d-flex align-items-center">
                              <input
                                type="text"
                                class="text-truncate"
                                id="FP"
                                placeholder="FP"
                                aria-label="FP 입력"
                                v-model="fittingreqinfo.club_fp"
                                readonly
                              />
                              <label for="FP" class="ms-1">㎜</label>
                            </p>
                          </div>
                        </div>
                        <div class="col">
                          <div class="d-flex flex-column">
                            <p class="mb-2">
                              <small class="rounded-circle bg-pink">3</small>솔
                            </p>
                            <p class="d-flex align-items-center">
                              <input
                                type="text"
                                class="text-truncate"
                                id="sole"
                                placeholder="솔"
                                aria-label="솔 입력"
                                v-model="fittingreqinfo.club_sol"
                                readonly
                              />
                              <label for="sole" class="ms-1">&deg;</label>
                            </p>
                          </div>
                        </div>
                        <div class="col">
                          <div class="d-flex flex-column">
                            <p class="mb-2">
                              <small class="rounded-circle bg-purple">4</small
                              >로프트
                            </p>
                            <p class="d-flex align-items-center">
                              <input
                                type="text"
                                class="text-truncate"
                                id="loft"
                                placeholder="로프트"
                                aria-label="로프트 입력"
                                v-model="fittingreqinfo.club_loft"
                                readonly
                              />
                              <label for="loft" class="ms-1">&deg;</label>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- //card-footer -->
                  </div>
                </div>

                <div class="col-md-4" v-if="fittingreqinfo.fit_club_type > 3">
                  <div class="card h-100">
                    <div class="card-body d-flex justify-content-center p-0">
                      <img
                        src="/img/fit_anls_iron_fv.png"
                        class="align-self-center img-fluid"
                        alt="클럽분석"
                      />
                    </div>
                    <!-- //card-body -->
                    <div
                      class="card-footer bg-white px-0 px-md-2 py-4 rounded-0 border-top"
                    >
                      <div class="row gx-2 gy-2 text-nowrap fs-14">
                        <div class="col">
                          <div class="d-flex flex-column">
                            <p class="mb-2">
                              <small class="rounded-circle bg-green">1</small
                              >라이
                            </p>
                            <p class="d-flex align-items-center">
                              <input
                                type="text"
                                class="text-truncate"
                                id="loft"
                                placeholder="라이"
                                aria-label="라이 입력"
                                v-model="fittingreqinfo.club_lie"
                                readonly
                              />
                              <label for="loft" class="ms-1">&deg;</label>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- //card-footer -->
                  </div>
                </div>
                <!-- //클럽분석 : 아이언, 웨지 -->
              </div>
            </div>
          </section>
          <!-- //구질 및 클럽 분석  -->

          <div class="btn-bottom-wrapper">
            <b-button-group>
              <b-button
                @click="preViewTabIndex--"
                size="xl"
                variant="outline-secondary btn-prev"
                disabled
                aria-label="이전"
              >
                <span class="material-icons-outlined">arrow_back_ios</span>
              </b-button>
              <b-button
                @click="preViewTabIndex++"
                size="xl"
                variant="outline-secondary btn-next"
                aria-label="다음"
              >
                <span class="material-icons-outlined">arrow_forward_ios</span>
              </b-button>
            </b-button-group>
          </div>
        </div>

        <!-- TabIndex : 1, 원포인트레슨 -->
        <div
          class="tab-pane fade show"
          :class="{ active: preViewTabIndex == 1 }"
        >
          <section class="fitting-lesson">
            <h6 class="sub-title">원 포인트 레슨</h6>
            <div class="row g-0 table-type header-col">
              <div class="col-sm-5 col-md-4">
                <span>{{ "lesson1" | get_lesson_title }}</span>
              </div>
              <div class="col-sm-7 col-md-8">
                <b-form-textarea
                  plaintext
                  class="mb-2"
                  :value="fittingreqinfo.lesson1"
                ></b-form-textarea>

                <div class="rating-wrapper d-sm-inline-flex">
                  <label for="rating-address">구분 동작별 평점</label>
                  <b-form-rating
                    id="rating-address"
                    :value="fittingreqinfo.lessonlevel1"
                    readonly
                    no-border
                    show-value
                    show-value-max
                    class="py-1"
                  ></b-form-rating>
                </div>
              </div>

              <div class="col-sm-5 col-md-4">
                <span>{{ "lesson2" | get_lesson_title }}</span>
              </div>
              <div class="col-sm-7 col-md-8">
                <b-form-textarea
                  plaintext
                  class="mb-2"
                  :value="fittingreqinfo.lesson2"
                ></b-form-textarea>
                <div class="rating-wrapper d-sm-inline-flex">
                  <label for="rating-address">구분 동작별 평점</label>
                  <b-form-rating
                    id="rating-address"
                    :value="fittingreqinfo.lessonlevel2"
                    no-border
                    readonly
                    show-value
                    show-value-max
                    class="py-1"
                  ></b-form-rating>
                </div>
              </div>

              <div class="col-sm-5 col-md-4">
                <span>{{ "lesson3" | get_lesson_title }}</span>
              </div>
              <div class="col-sm-7 col-md-8">
                <b-form-textarea
                  plaintext
                  class="mb-2"
                  :value="fittingreqinfo.lesson3"
                ></b-form-textarea>
                <div class="rating-wrapper d-sm-inline-flex">
                  <label for="rating-address">구분 동작별 평점</label>
                  <b-form-rating
                    id="rating-address"
                    :value="fittingreqinfo.lessonlevel3"
                    no-border
                    readonly
                    show-value
                    show-value-max
                    class="py-1"
                  ></b-form-rating>
                </div>
              </div>

              <div class="col-sm-5 col-md-4">
                <span>{{ "lesson4" | get_lesson_title }}</span>
              </div>
              <div class="col-sm-7 col-md-8">
                <b-form-textarea
                  plaintext
                  class="mb-2"
                  :value="fittingreqinfo.lesson4"
                ></b-form-textarea>
                <div class="rating-wrapper d-sm-inline-flex">
                  <label for="rating-address">구분 동작별 평점</label>
                  <b-form-rating
                    id="rating-address"
                    :value="fittingreqinfo.lessonlevel4"
                    no-border
                    readonly
                    show-value
                    show-value-max
                    class="py-1"
                  ></b-form-rating>
                </div>
              </div>

              <div class="col-sm-5 col-md-4">
                <span>{{ "lesson5" | get_lesson_title }}</span>
              </div>
              <div class="col-sm-7 col-md-8">
                <b-form-textarea
                  plaintext
                  class="mb-2"
                  :value="fittingreqinfo.lesson5"
                ></b-form-textarea>
                <div class="rating-wrapper d-sm-inline-flex">
                  <label for="rating-address">구분 동작별 평점</label>
                  <b-form-rating
                    id="rating-address"
                    :value="fittingreqinfo.lessonlevel5"
                    no-border
                    readonly
                    show-value
                    show-value-max
                    class="py-1"
                  ></b-form-rating>
                </div>
              </div>

              <div class="col-sm-5 col-md-4">
                <span>{{ "lesson6" | get_lesson_title }}</span>
              </div>
              <div class="col-sm-7 col-md-8">
                <b-form-textarea
                  plaintext
                  class="mb-2"
                  :value="fittingreqinfo.lesson6"
                ></b-form-textarea>
                <div class="rating-wrapper d-sm-inline-flex">
                  <label for="rating-address">구분 동작별 평점</label>
                  <b-form-rating
                    id="rating-address"
                    :value="fittingreqinfo.lessonlevel6"
                    no-border
                    readonly
                    show-value
                    show-value-max
                    class="py-1"
                  ></b-form-rating>
                </div>
              </div>

              <div class="col-sm-5 col-md-4">
                <span>{{ "lesson7" | get_lesson_title }}</span>
              </div>
              <div class="col-sm-7 col-md-8">
                <b-form-textarea
                  plaintext
                  class="mb-2"
                  :value="fittingreqinfo.lesson7"
                ></b-form-textarea>
                <div class="rating-wrapper d-sm-inline-flex">
                  <label for="rating-address">구분 동작별 평점</label>
                  <b-form-rating
                    id="rating-address"
                    :value="fittingreqinfo.lessonlevel7"
                    no-border
                    readonly
                    show-value
                    show-value-max
                    class="py-1"
                  ></b-form-rating>
                </div>
              </div>

              <div class="col-sm-5 col-md-4 border-sm-0">
                <span>{{ "lessontotal" | get_lesson_title }}</span>
              </div>
              <div class="col-sm-7 col-md-8">
                <b-form-textarea
                  plaintext
                  :value="fittingreqinfo.lessontotal"
                ></b-form-textarea>
              </div>
            </div>
          </section>

          <div class="btn-bottom-wrapper">
            <b-button-group>
              <b-button
                @click="preViewTabIndex--"
                size="xl"
                variant="outline-secondary btn-prev"
                aria-label="이전"
              >
                <span class="material-icons-outlined">arrow_back_ios</span>
              </b-button>
              <b-button
                @click="preViewTabIndex++"
                size="xl"
                variant="outline-secondary btn-next"
                aria-label="다음"
              >
                <span class="material-icons-outlined">arrow_forward_ios</span>
              </b-button>
            </b-button-group>
          </div>
        </div>

        <!-- TabIndex : 2, 고급형 -->
        <div
          class="tab-pane fade show"
          :class="{ active: preViewTabIndex == 2 }"
        >
          <section>
            <h6 class="sub-title">
              <span class="brand-name fw-bold">{{
                form.p_info.fit_brand_nm
              }}</span
              >에서 추천하는
              <span class="text-green">고급형</span>
            </h6>
            <div class="card m-0 p-0 bg-transparent border-0 rounded-0">
              <div class="row g-0 table-type header-col">
                <div class="col-sm-3">
                  <div
                    class="d-flex flex-fill justify-content-center h-100 bg-white"
                  >
                    <img
                      :src="form.p_info.goodsinfo.fit_goods_image1 | get_img"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <div class="col-sm-9 d-flex flex-column align-items-stretch">
                  <div class="card-body px-0 px-sm-3">
                    <h4 class="card-title">
                      {{ form.p_info.goodsinfo.fit_goods_nm }}
                    </h4>
                    <div class="row g-0 row-cols-1 row-cols-sm-2">
                      <div
                        class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fs-6 fw-bold text-black"
                      >
                        매장명
                      </div>
                      <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                        <h5>
                          {{ fittingreqinfo.shopname | comma }}
                        </h5>
                      </div>

                      <div
                        class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fs-6 fw-bold text-black"
                      >
                        소비자가
                      </div>
                      <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                        <h5 class="text-price">
                          &#8361;
                          {{ form.p_info.fit_sale_price | comma }}
                        </h5>
                      </div>

                      <div
                        class="col-sm-4 mt-3 mt-sm-0 py-0 py-sm-2 border-top-sm fs-6 fw-bold text-black"
                      >
                        구매혜택
                      </div>
                      <div class="col-sm-8 py-0 py-sm-2 border-top-sm fs-6">
                        {{ form.p_info.goodsinfo.fit_goods_info }}
                        <!-- 미등록 -->
                      </div>
                    </div>
                  </div>
                  <!-- //card-body -->
                  <div
                    class="card-footer px-0 px-sm-3 py-0 bg-transparent border-0 rounded-0"
                  >
                    <div class="btn-bottom-wrapper mt-2 mb-4 mt-sm-0 mb-sm-3">
                      <b-button
                        size="md"
                        variant="primary"
                        aria-label="제품구매"
                        @click="fittingBuy(form.p_info.fit_ans_pk)"
                      >
                        제품구매
                      </b-button>
                    </div>
                  </div>
                  <!-- //card-footer -->
                </div>
              </div>
            </div>
          </section>
          <!-- //기본 정보 등록 -->
          <section>
            <h6 class="sub-title">제품 상세 정보</h6>
            <div class="row g-0 table-type header-col">
              <img
                v-if="form.p_info.goodsinfo.fit_goods_image2"
                :src="form.p_info.goodsinfo.fit_goods_image2 | get_img"
                class="img-fluid"
              />
              <img
                v-if="form.p_info.goodsinfo.fit_goods_image3"
                :src="form.p_info.goodsinfo.fit_goods_image3 | get_img"
                class="img-fluid"
              />
            </div>
          </section>
          <section class="reg-spec">
            <h6 class="sub-title">스펙</h6>
            <b-table-simple responsive table-class="border-0">
              <b-thead>
                <b-tr>
                  <b-th sticky-column>클럽</b-th>
                  <b-th
                    v-for="(val, idx) in form.p_info.ansclublist"
                    :key="'p_clubnotype' + idx"
                  >
                    {{
                      fittingreqinfo.clubtypelist.find(
                        (v) => v.clubtype_code == val.fit_club_nm
                      ).clubtype_code_name
                    }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-th sticky-column>로프트(&deg;)</b-th>
                  <b-td
                    v-for="(val, idx) in form.p_info.ansclublist"
                    :key="'p_clubloft' + idx"
                  >
                    {{ val.fit_club_loft }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th sticky-column>라이(&deg;)</b-th>
                  <b-td
                    v-for="(val, idx) in form.p_info.ansclublist"
                    :key="'p_clublie' + idx"
                  >
                    {{ val.fit_club_lie }}
                  </b-td>
                </b-tr>

                <b-tr v-if="form.p_info.fit_club_type < 4">
                  <b-th sticky-column>헤드볼륨(㎤)</b-th>
                  <b-td
                    v-for="(val, idx) in form.p_info.ansclublist"
                    :key="'p_clubheadvolume' + idx"
                  >
                    {{ val.fit_club_headvolume }}
                  </b-td>
                </b-tr>
                <b-tr v-if="form.p_info.fit_club_type > 3">
                  <b-th sticky-column>솔(&deg;)</b-th>
                  <b-td
                    v-for="(val, idx) in form.p_info.ansclublist"
                    :key="'p_clubsole' + idx"
                  >
                    {{ val.fit_club_sol }}
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th sticky-column>FP(mm)</b-th>
                  <b-td
                    v-for="(val, idx) in form.p_info.ansclublist"
                    :key="'p_clubfp' + idx"
                    >{{ val.fit_club_fp }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th sticky-column>길이(Inch)</b-th>
                  <b-td
                    v-for="(val, idx) in form.p_info.ansclublist"
                    :key="'p_clublength' + idx"
                  >
                    {{ val.fit_club_length }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th sticky-column rowspan="5">
                    <div class="sticky-column-rowspan">
                      <div>밸런스</div>
                      <div class="flex-column">
                        <div v-if="form.p_info.fit_balance1 != '0'">
                          {{ form.p_info.fit_balance1 | get_balance }}
                        </div>
                        <div v-if="form.p_info.fit_balance2 != '0'">
                          {{ form.p_info.fit_balance2 | get_balance }}
                        </div>
                        <div v-if="form.p_info.fit_balance3 != '0'">
                          {{ form.p_info.fit_balance3 | get_balance }}
                        </div>
                        <div v-if="form.p_info.fit_balance4 != '0'">
                          {{ form.p_info.fit_balance4 | get_balance }}
                        </div>
                        <div
                          class="py-2 pe-2"
                          v-if="form.p_info.fit_balance5 != '0'"
                        >
                          {{ form.p_info.fit_balance5 | get_balance }}
                        </div>
                      </div>
                    </div>
                  </b-th>
                  <template v-for="(val, idx) in form.p_info.ansclublist">
                    <b-td
                      :key="'p_clubflextitle1' + idx"
                      v-if="val.fit_club_balance1"
                    >
                      {{ val.fit_club_balance1 }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <template v-for="(val, idx) in form.p_info.ansclublist">
                    <b-td
                      :key="'p_clubflextitle2' + idx"
                      v-if="val.fit_club_balance2"
                    >
                      {{ val.fit_club_balance2 }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <template v-for="(val, idx) in form.p_info.ansclublist">
                    <b-td
                      :key="'p_clubflextitle3' + idx"
                      v-if="val.fit_club_balance3"
                    >
                      {{ val.fit_club_balance3 }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <template v-for="(val, idx) in form.p_info.ansclublist">
                    <b-td
                      :key="'p_clubflextitle4' + idx"
                      v-if="val.fit_club_balance4"
                    >
                      {{ val.fit_club_balance4 }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <template v-for="(val, idx) in form.p_info.ansclublist">
                    <b-td
                      :key="'p_clubflextitle5' + idx"
                      v-if="val.fit_club_balance5"
                    >
                      {{ val.fit_club_balance5 }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <b-th sticky-column class="border-0">총중량(g)</b-th>
                  <b-td
                    v-for="(val, idx) in form.p_info.ansclublist"
                    :key="'p_clubweight' + idx"
                  >
                    {{ val.fit_club_weight }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </section>
          <!-- 스펙 등록 -->

          <section class="reg-etc">
            <div class="row gx-4 gy-5 border-0">
              <div class="col-lg-6 d-flex flex-column">
                <h6 class="sub-title">샤프트</h6>
                <div class="card h-100">
                  <div class="card-body d-flex flex-column p-0">
                    <p class="card-title">
                      {{ form.p_info.shaftinfo.fit_goods_shaft_nm }}
                      /
                      {{
                        form.p_info.shaftinfo.fit_shaft_material
                          | get_shaft_material
                      }}
                    </p>
                    <div
                      class="d-flex justify-content-center align-items-center h-100 py-3"
                    >
                      <img
                        :src="
                          form.p_info.shaftinfo.fit_goods_shaft_image1 | get_img
                        "
                        class="img-fluid"
                        alt="샤트프"
                      />
                    </div>
                  </div>
                  <div class="card-footer">
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">강도</th>
                          <th scope="col">무게(g)</th>
                          <th scope="col">토크(˚)</th>
                          <th scope="col">킥포인트</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {{
                              form.p_info.shaftinfo.fit_goods_shaft_balance
                                | get_balance
                            }}
                          </td>
                          <td>
                            {{ form.p_info.shaftinfo.fit_goods_shaft_weight }}
                          </td>
                          <td>
                            {{ form.p_info.shaftinfo.fit_goods_shaft_torque }}
                          </td>
                          <td>
                            {{
                              form.p_info.shaftinfo.fit_goods_shaft_kickpoint
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 d-flex flex-column">
                <h6 class="sub-title">그립</h6>
                <div class="card h-100">
                  <div class="card-body d-flex flex-column p-0">
                    <p class="card-title">
                      {{ form.p_info.gripinfo.fit_goods_grip_nm }}}
                    </p>
                    <div
                      class="d-flex justify-content-center align-items-center h-100 py-3"
                    >
                      <img
                        :src="
                          form.p_info.gripinfo.fit_goods_grip_image1 | get_img
                        "
                        class="img-fluid"
                        alt="그립"
                      />
                    </div>
                  </div>
                  <div class="card-footer">
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">규격</th>
                          <th scope="col">무게(g)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {{ form.p_info.gripinfo.fit_goods_grip_standard }}
                          </td>
                          <td>
                            {{ form.p_info.gripinfo.fit_goods_grip_weight }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- 샤프트 & 그립 등록 -->

          <div class="btn-bottom-wrapper">
            <b-button-group>
              <b-button
                @click="preViewTabIndex--"
                size="xl"
                variant="outline-secondary btn-prev"
                aria-label="이전"
              >
                <span class="material-icons-outlined">arrow_back_ios</span>
              </b-button>
              <b-button
                @click="preViewTabIndex++"
                size="xl"
                variant="outline-secondary btn-next"
                aria-label="다음"
              >
                <span class="material-icons-outlined">arrow_forward_ios</span>
              </b-button>
            </b-button-group>
          </div>
        </div>

        <!-- TabIndex : 3, 보급형 -->
        <div
          class="tab-pane fade show"
          :class="{ active: preViewTabIndex == 3 }"
        >
          <section>
            <h6 class="sub-title">
              <span class="brand-name fw-bold">{{
                form.c_info.fit_brand_nm
              }}</span
              >에서 추천하는
              <span class="text-green">보급형</span>
            </h6>
            <div class="card m-0 p-0 bg-transparent border-0 rounded-0">
              <div class="row g-0 table-type header-col">
                <div class="col-sm-3">
                  <div
                    class="d-flex flex-fill justify-content-center h-100 bg-white"
                  >
                    <img
                      :src="form.c_info.goodsinfo.fit_goods_image1 | get_img"
                      class="img-fluid rounded-0"
                    />
                  </div>
                </div>
                <div class="col-sm-9 d-flex flex-column align-items-stretch">
                  <div class="card-body px-0 px-sm-3">
                    <h4 class="card-title">
                      {{ form.c_info.goodsinfo.fit_goods_nm }}
                    </h4>
                    <div class="row g-0 row-cols-1 row-cols-sm-2">
                      <div
                        class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fs-6 fw-bold text-black"
                      >
                        매장명
                      </div>
                      <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                        <h5>
                          {{ fittingreqinfo.shopname }}
                        </h5>
                      </div>

                      <div
                        class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fs-6 fw-bold text-black"
                      >
                        소비자가
                      </div>
                      <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                        <h5 class="text-price">
                          &#8361;
                          {{ form.c_info.fit_sale_price | comma }}
                        </h5>
                      </div>

                      <div
                        class="col-sm-4 mt-3 mt-sm-0 py-0 py-sm-2 border-top-sm fs-6 fw-bold text-black"
                      >
                        구매혜택
                      </div>
                      <div class="col-sm-8 py-0 py-sm-2 border-top-sm fs-6">
                        {{ form.c_info.goodsinfo.fit_goods_info }}
                        <!-- 미등록 -->
                      </div>
                    </div>
                  </div>
                  <!-- //card-body -->
                  <div
                    class="card-footer px-0 px-sm-3 py-0 bg-transparent border-0 rounded-0"
                  >
                    <div class="btn-bottom-wrapper mt-2 mb-4 mt-sm-0 mb-sm-3">
                      <b-button
                        size="md"
                        variant="primary"
                        aria-label="제품구매"
                        @click="fittingBuy(form.c_info.fit_ans_pk)"
                      >
                        제품구매
                      </b-button>
                    </div>
                  </div>
                  <!-- //card-footer -->
                </div>
              </div>
            </div>
          </section>
          <!-- //기본 정보 등록 -->
          <section>
            <h6 class="sub-title">제품 상세 정보</h6>
            <div class="row g-0 table-type header-col">
              <img
                :src="form.c_info.goodsinfo.fit_goods_image2 | get_img"
                class="img-fluid rounded-0"
              />
              <img
                :src="form.c_info.goodsinfo.fit_goods_image3 | get_img"
                class="img-fluid rounded-0"
              />
            </div>
          </section>
          <section class="reg-spec">
            <h6 class="sub-title">스펙</h6>
            <b-table-simple responsive table-class="border-0">
              <b-thead>
                <b-tr>
                  <b-th sticky-column>클럽</b-th>
                  <b-th
                    v-for="(val, idx) in form.c_info.ansclublist"
                    :key="'p_clubnotype' + idx"
                  >
                    {{
                      fittingreqinfo.clubtypelist.find(
                        (v) => v.clubtype_code == val.fit_club_nm
                      ).clubtype_code_name
                    }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-th sticky-column>로프트(&deg;)</b-th>
                  <b-td
                    v-for="(val, idx) in form.c_info.ansclublist"
                    :key="'p_clubloft' + idx"
                  >
                    {{ val.fit_club_loft }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th sticky-column>라이(&deg;)</b-th>
                  <b-td
                    v-for="(val, idx) in form.c_info.ansclublist"
                    :key="'p_clublie' + idx"
                  >
                    {{ val.fit_club_lie }}
                  </b-td>
                </b-tr>
                <b-tr v-if="form.c_info.fit_club_type < 4">
                  <b-th sticky-column>헤드볼륨(㎤)</b-th>
                  <b-td
                    v-for="(val, idx) in form.c_info.ansclublist"
                    :key="'c_clubheadvolume' + idx"
                  >
                    {{ val.fit_club_headvolume }}
                  </b-td>
                </b-tr>
                <b-tr v-if="form.c_info.fit_club_type > 3">
                  <b-th sticky-column>솔(&deg;)</b-th>
                  <b-td
                    v-for="(val, idx) in form.c_info.ansclublist"
                    :key="'c_clubsole' + idx"
                  >
                    {{ val.fit_club_sol }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th sticky-column>FP(mm)</b-th>
                  <b-td
                    v-for="(val, idx) in form.c_info.ansclublist"
                    :key="'p_clubfp' + idx"
                    >{{ val.fit_club_fp }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th sticky-column>길이(Inch)</b-th>
                  <b-td
                    v-for="(val, idx) in form.c_info.ansclublist"
                    :key="'p_clublength' + idx"
                  >
                    {{ val.fit_club_length }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th sticky-column rowspan="5">
                    <div class="sticky-column-rowspan">
                      <div>밸런스</div>
                      <div class="flex-column">
                        <div v-if="form.c_info.fit_balance1 != '0'">
                          {{ form.c_info.fit_balance1 | get_balance }}
                        </div>
                        <div v-if="form.c_info.fit_balance2 != '0'">
                          {{ form.c_info.fit_balance2 | get_balance }}
                        </div>
                        <div v-if="form.c_info.fit_balance3 != '0'">
                          {{ form.c_info.fit_balance3 | get_balance }}
                        </div>
                        <div v-if="form.c_info.fit_balance4 != '0'">
                          {{ form.c_info.fit_balance4 | get_balance }}
                        </div>
                        <div
                          class="py-2 pe-2"
                          v-if="form.c_info.fit_balance5 != '0'"
                        >
                          {{ form.c_info.fit_balance5 | get_balance }}
                        </div>
                      </div>
                    </div>
                  </b-th>
                  <template v-for="(val, idx) in form.c_info.ansclublist">
                    <b-td
                      :key="'p_clubflextitle1' + idx"
                      v-if="val.fit_club_balance1"
                    >
                      {{ val.fit_club_balance1 }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <template v-for="(val, idx) in form.c_info.ansclublist">
                    <b-td
                      :key="'p_clubflextitle2' + idx"
                      v-if="val.fit_club_balance2"
                    >
                      {{ val.fit_club_balance2 }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <template v-for="(val, idx) in form.c_info.ansclublist">
                    <b-td
                      :key="'p_clubflextitle3' + idx"
                      v-if="val.fit_club_balance3"
                    >
                      {{ val.fit_club_balance3 }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <template v-for="(val, idx) in form.c_info.ansclublist">
                    <b-td
                      :key="'p_clubflextitle4' + idx"
                      v-if="val.fit_club_balance4"
                    >
                      {{ val.fit_club_balance4 }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <template v-for="(val, idx) in form.c_info.ansclublist">
                    <b-td
                      :key="'p_clubflextitle5' + idx"
                      v-if="val.fit_club_balance5"
                    >
                      {{ val.fit_club_balance5 }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <b-th sticky-column class="border-0">총중량(g)</b-th>
                  <b-td
                    v-for="(val, idx) in form.c_info.ansclublist"
                    :key="'p_clubweight' + idx"
                  >
                    {{ val.fit_club_weight }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </section>
          <!-- 스펙 등록 -->

          <section class="reg-etc">
            <div class="row gx-4 gy-5 border-0">
              <div class="col-lg-6 d-flex flex-column">
                <h6 class="sub-title">샤프트</h6>
                <div class="card h-100">
                  <div class="card-body d-flex flex-column p-0">
                    <p class="card-title">
                      {{ form.c_info.shaftinfo.fit_goods_shaft_nm }}
                      /
                      {{
                        form.c_info.shaftinfo.fit_shaft_material
                          | get_shaft_material
                      }}
                    </p>
                    <div
                      class="d-flex justify-content-center align-items-center h-100 py-3"
                    >
                      <img
                        :src="
                          form.c_info.shaftinfo.fit_goods_shaft_image1 | get_img
                        "
                        class="img-fluid"
                        alt="샤트프"
                      />
                    </div>
                  </div>
                  <div class="card-footer">
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">강도</th>
                          <th scope="col">무게(g)</th>
                          <th scope="col">토크(˚)</th>
                          <th scope="col">킥포인트</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {{
                              form.c_info.shaftinfo.fit_goods_shaft_balance
                                | get_balance
                            }}
                          </td>
                          <td>
                            {{ form.c_info.shaftinfo.fit_goods_shaft_weight }}
                          </td>
                          <td>
                            {{ form.c_info.shaftinfo.fit_goods_shaft_torque }}
                          </td>
                          <td>
                            {{
                              form.c_info.shaftinfo.fit_goods_shaft_kickpoint
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 d-flex flex-column">
                <h6 class="sub-title">그립</h6>
                <div class="card h-100">
                  <div class="card-body d-flex flex-column p-0">
                    <p class="card-title">
                      {{ form.c_info.gripinfo.fit_goods_grip_nm }}}
                    </p>
                    <div
                      class="d-flex justify-content-center align-items-center h-100 py-3"
                    >
                      <img
                        :src="
                          form.c_info.gripinfo.fit_goods_grip_image1 | get_img
                        "
                        class="img-fluid"
                        alt="그립"
                      />
                    </div>
                  </div>
                  <div class="card-footer">
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">규격</th>
                          <th scope="col">무게(g)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {{ form.c_info.gripinfo.fit_goods_grip_standard }}
                          </td>
                          <td>
                            {{ form.c_info.gripinfo.fit_goods_grip_weight }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="btn-bottom-wrapper">
            <b-button-group>
              <b-button
                @click="preViewTabIndex--"
                size="xl"
                variant="outline-secondary btn-prev"
                aria-label="이전"
              >
                <span class="material-icons-outlined">arrow_back_ios</span>
              </b-button>
              <b-button
                @click="preViewTabIndex++"
                size="xl"
                variant="outline-secondary btn-next"
                disabled
                aria-label="다음"
              >
                <span class="material-icons-outlined">arrow_forward_ios</span>
              </b-button>
            </b-button-group>
          </div>
        </div>

        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="outline-primary"
            aria-label="목록"
            @click="
              $router.push({
                name: 'FittingList',
                query: { goodstype: goodstype },
              })
            "
            >목록</b-button
          >
        </div>
      </div>
      <!-- //tab-content -->
    </div>
  </div>
</template>

<script>
import locations from "@/components/Location";
import ax from "@/api/fitting";
import "@/filter/common";
import "swiper/css/swiper.css";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { Radar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");

export default {
  name: "FittingAnswerView",
  components: {
    locations,
    Radar,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      form: {
        impactgrade: "1",
        distancegrade: "1",
        directiongrade: "1",
        accuracygrade: "1",
        positiongrade: "1",

        p_info: {
          goodsinfo: {},
          memberinfo: {},
          answerinfo: [],
          gripinfo: {},
          shaftinfo: {},
        },
        c_info: {
          goodsinfo: {},
          memberinfo: {},
          answerinfo: [],
          gripinfo: {},
          shaftinfo: {},
        },
      },

      fitreqno: this.$route.params.fitreqno,

      fittingreqinfo: {
        adinfo: {
          ad_img1_info: {},
          ad_img2_info: {},
          ad_img3_info: {},
          ad_mov_info: {},
          ad_title_info: {},
        },

        clubtypelist: [],
      },
      preViewTabIndex: 0,

      radaroptions: {
        scale: {
          r: {
            min: 0, // MIN
            max: 5, // MAX
            beginAtZero: true,
            angleLines: {
              display: true,
              // color: 'red',
            },
            grid: {
              circular: true,
            },
          },
          ticks: {
            stepSize: 1,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
      },
      resultradardata: {
        labels: ["거리", "방향성", "정확도", "자세", "임팩트"],
        datasets: [
          {
            label: "My First dataset",
            backgroundColor: "rgba(179,181,198,0.2)",
            borderColor: "rgba(179,181,198,1)",
            pointBackgroundColor: "rgba(179,181,198,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(179,181,198,1)",
            data: [],
          },
        ],
      },
      watching: true,
    };
  },

  created() {
    this.get_fittinganswerview().then((res) => {
      this.watching = res;
      this.resultradardata.datasets[0].data = [
        this.form.distancegrade,
        this.form.directiongrade,
        this.form.accuracygrade,
        this.form.positiongrade,
        this.form.impactgrade,
      ];
    });
  },

  methods: {
    get_fittinganswerview() {
      return new Promise((resolve, reject) => {
        ax.get_fittinganswerview(this.fitreqno, (flag, data) => {
          if (flag) {
            this.fittingreqinfo = data.fittingreqinfo;

            this.form.resulttxt = this.fittingreqinfo.fit_ans_txt;
            this.form._resultfilename = this.fittingreqinfo.fit_ans_file;
            this.form.impactgrade = this.fittingreqinfo.grade_impact;

            this.form.distancegrade = this.fittingreqinfo.grade_distance;
            this.form.directiongrade = this.fittingreqinfo.grade_direction;
            this.form.accuracygrade = this.fittingreqinfo.grade_accuracy;
            this.form.positiongrade = this.fittingreqinfo.grade_position;

            this.fittingreqinfo.answerinfo.forEach((val) => {
              if (val.fit_goods_grade === "P") {
                this.form.p_info = val;
              }
              if (val.fit_goods_grade === "C") {
                this.form.c_info = val;
              }
            });
            console.log(
              "🚀 ~ file: FittingAnswerView.vue:1630 ~ this.fittingreqinfo.answerinfo.forEach ~ this.form.p_info",
              this.form.p_info
            );
            console.log(
              "🚀 ~ file: FittingAnswerView.vue:1634 ~ this.fittingreqinfo.answerinfo.forEach ~ this.form.c_info",
              this.form.c_info
            );
            resolve(true);
          } else {
            reject(false);
            alert(data.message);
          }
        });
      });
    },
    fittingBuy(fit_ans_pk) {
      ax.put_fittingBuy(fit_ans_pk, (flag, response) => {
        alert(response.message);
        if (flag) {
          this.$router.push({
            name: "FittingList",
          });
        } else {
        }
      });
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    print() {
      window.print();
    },
  },
  watch: {},
};
</script>