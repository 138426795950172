<template>
  <div>
    <location />

    <div class="p15 page">
      <div class="wrap-1200">
        <h3 class="subtitle">요약 정보</h3>
        <div class="row g-0 border-top-dgray">
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">평균 점수</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell">{{ summaryinfo.avgscore | comma }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">최대 드라이버 거리</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell">
                  {{ summaryinfo.maxdriver | comma }} m
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-0">
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">평균 퍼트 수</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell">
                  {{ summaryinfo.avggreenhit | comma }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">스윙 영상 수</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell">
                  {{ summaryinfo.swingcnt | comma }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap-1200">
        <router-link :to="{ name: 'MyStatus' }">
          <h3 class="subtitle">
            최근 경기<span class="material-icons-outlined"
              >navigate_next</span
            >
          </h3>
        </router-link>
        <div class="row g-0 border-top-dgray">
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">날짜</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell date">
                  {{ recentplayinfo.date | GMTtuUTC }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">코스</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell">{{ recentplayinfo.coursename }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-0">
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">점수</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell">{{ recentplayinfo.score | comma }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">타수</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell">{{ recentplayinfo.shot | comma }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-0">
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">최대 드라이버 거리</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell">
                  {{ recentplayinfo.maxdriver | comma }} m
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">평균 드라이버 거리</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell">
                  {{ recentplayinfo.avgdriver | comma }} m
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-0">
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">페어웨이 안착률</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell">
                  {{ recentplayinfo.farewayrate | comma }} %
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">파 세이브율</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell">
                  {{ recentplayinfo.parsaverate | comma }} %
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-0">
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">그린 적중률</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell">
                  {{ recentplayinfo.greenrate | comma }} %
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 border-bottom">
            <div class="row g-0">
              <div class="col-6 bg-gray row g-0 align-items-center">
                <label class="d-table-cell">평균 퍼트 수</label>
              </div>
              <div class="col-6">
                <p class="d-table-cell">{{ recentplayinfo.avggreenhit }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap-1200 border-bottom-0">
        <router-link :to="{ name: 'MySwing' }">
          <h3 class="subtitle">
            최근 스윙 영상<span class="material-icons-outlined"
              >navigate_next</span
            >
          </h3>
        </router-link>
        <table class="board table" id="dataTable" width="100%" cellspacing="0">
          <thead>
            <tr>
              <th class="col-auto d-lg-table-cell text-center">스윙 영상</th>
              <th class="col-auto d-lg-table-cell text-center">매장</th>
              <th class="col-auto d-none d-lg-table-cell text-center">날짜</th>
              <th class="th-arrow">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr class="btn1" @click="toggle_tr(1)">
              <td class="col-auto d-lg-table-cell">
                <router-link
                  :to="{
                    name: 'MySwingView',
                    params: { id: swinginfo.swingno },
                  }"
                  class="position-relative video-box"
                >
                  <video
                    :src="swinginfo.path + '/' + swinginfo.swingmovie"
                  ></video>
                  <div class="mask"></div>
                  <span class="icon-play"></span>
                </router-link>
              </td>
              <td class="col-auto d-lg-table-cell text-center">
                {{ swinginfo.shopname }}
              </td>
              <td class="col-auto d-none d-lg-table-cell text-center text-date">
                {{ swinginfo.date | GMTtuUTCTIME }}
              </td>
              <td class="td-arrow">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === 1 }"
                />
              </td>
            </tr>
            <tr
              class="col-auto d-lg-none d-md-tabel-cell"
              :class="{ show: view_hide === 1 }"
              v-if="view_hide === 1"
            >
              <td class="align-left bg-white" colspan="3">
                <span class="d-inline-block me-2 fw-bold text-black">날짜</span><span class="date">2022.01.02{{ swinginfo.date | GMTtuUTCTIME }}</span>
                <!--
                <router-link
                  :to="{
                    name: 'MySwingView',
                    params: { id: swinginfo.swingno }
                  }"
                  class="btn1 btn_view"
                >
                  <img src="/img/ico_edit.png" />
                </router-link>
                -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/membership";
import "@/filter/common";

export default {
  name: "MySummary",
  components: {
    location,
  },
  data() {
    return {
      summaryinfo: [],
      recentplayinfo: [],
      swinginfo: [],
      view_hide: null,
    };
  },
  methods: {
    get_membersummary() {
      ax.get_membersummary((flag, data, code) => {
        if (flag) {
          this.summaryinfo = data.summaryinfo;
          this.recentplayinfo = data.recentplayinfo;
          this.swinginfo = data.swinginfo;
        } else {
          if (code == "300") {
            alert(data.message);
            this.$notify({
              // (optional)
              // Name of the notification holder
              group: "alert",

              // (optional)
              // Class that will be assigned to the notification
              type: "warn",

              // (optional)
              // Title (will be wrapped in div.notification-title)

              // Content (will be wrapped in div.notification-content)
              text: data.message,

              // (optional)
              // Overrides default/provided duration
              duration: 5000,

              // (optional)
              // Overrides default/provided animation speed
              speed: 1000,

              // (optional)
              // Data object that can be used in your template
            });
          } else alert(data.message);
          if (code === "110") {
            this.$store.dispatch("logout");
            this.$router.push("/");
          }
        }
      });
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_membersummary();
  },
};
</script>