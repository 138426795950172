<template>
  <div>
    <location />

    <div class="page wrap-1200">
      <h3 class="subtitle">X-GOLF 토너먼트에 참가하여 챔피언에 도전하세요!</h3>
      <p>
        X-GOLF 멤버십 회원은 전국 X-GOLF 매장 어디서나 지역 또는 매장 단위의 토너먼트 참가를 통해
        세계 최고 수준의 골프 코스, 최첨단 골프 시뮬레이터에서 자신의 능력을 뽐내고 경쟁하며, 친목을
        다질 수 있는 기회를 갖는 동시에 우승 시 다양한 상금과 각종 부상이 주어집니다.
      </p>
      <h5>토너먼트 참여안내</h5>
      <p>토너먼트에 참가하시려면 X-GOLF 멤버십에 회원가입하셔야 합니다.</p>
      <ul>
        <li>
          <span class="badge rounded-pill bg-lime">STEP 1</span>
          <h6>회원가입</h6>
          <p>X-GOLF 공식 홈페이지를 통해 멤버십에 회원가입합니다.</p>
          <i class="material-icons-outlined">keyboard_backspace</i>
        </li>
        <li>
          <span class="badge rounded-pill bg-lime">STEP 2</span>
          <h6>이벤트 모드로 라운딩</h6>
          <p>
            매장에서 로그인 후 대회 코스를 선택하고 이벤트 모드로 경기에 참가합니다.
          </p>
          <i class="material-icons-outlined">keyboard_backspace</i>
        </li>
        <li>
          <span class="badge rounded-pill bg-lime">STEP 3</span>
          <h6>대회 결과 확인</h6>
          <p>
            '멤버십 > 나의 기록' 또는 토너먼트 메뉴에서 대회 결과 및 스코어 카드를 확인할 수 있습니다.
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import location from '@/components/Location'

export default {
  name: 'JoinTournament',
  components: {
    location
  }
}
</script>