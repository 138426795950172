<template>
  <div>
    <location />

<!--     <div :class="[{ p30: tab === 1 }, { p31: tab === 2 }]" class="page">
 --><div :class="[{ p30: tab === 1 }, { p31: tab === 2 }]" class="page wrap-1200">
      <swiper
        :options="{
          slidesPerView: 'auto',
          freeMode: true,
          initialSlide: tab - 1,
        }"
        class="swiper mySwiper"
      >
        <swiper-slide :class="{ active: tab === 1 }"
          ><div @click="tab_change(1)">토너먼트 요강</div></swiper-slide
        >
        <swiper-slide :class="{ active: tab === 2 }"
          ><div @click="tab_change(2)">토너먼트 순위</div></swiper-slide
        >
      </swiper>
      <ul class="nav nav-pills wrap-1200" id="pills-tab" role="tablist">
        <li class="nav-item col-6" role="presentation">
          <button
            class="w-100 nav-link"
            id="pills-1-tab"
            type="button"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            :class="{ active: tab == 1 }"
            @click="tab_change(1)"
          >
            토너먼트 요강
          </button>
        </li>
        <li class="nav-item col-6" role="presentation">
          <button
            class="w-100 nav-link"
            id="pills-2-tab"
            type="button"
            role="tab"
            aria-controls="pills-2"
            aria-selected="false"
            :class="{ active: tab === 2 }"
            @click="tab_change(2)"
          >
            토너먼트 순위
          </button>
        </li>
      </ul>

      <div
        class="tab-content"
        id="pills-tabContent"
        :class="{ 'wrap-1200': tab === 1 }"
      >
        <Router-View />
      </div>
<!--       <div class="wrap-1200 start-m row g-0 center-btn">
        <div class="col-lg-4 d-table m-auto">
          <button
            class="btn btn-primary btn-xl"
            type="button"
            @click="
              $router
                .push({
                  name: 'TournamentSchedule',
                  query: {
                    searchtype: $route.query.searchtype,
                    searchname: $route.query.searchname,
                  },
                })
                .catch(() => {})
            "
          >
            목록
          </button>
        </div>
      </div> -->

      <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="outline-primary"
            aria-label="목록"
            @click="
              $router
                .push({
                  name: 'TournamentSchedule',
                  query: {
                    searchtype: $route.query.searchtype,
                    searchname: $route.query.searchname,
                  },
                })
                .catch(() => {})
            "
            >목록</b-button
          >
      </div>

    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "TournamentScheduleView",
  components: {
    location,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /*swiperOptions: {
        slidesPerView: 'auto',
        freeMode: true
      }*/
    };
  },
  computed: {
    tab: function () {
      return this.$route.name === "Ranking" ? 2 : 1;
    },
  },
  methods: {
    tab_change(tab_no) {
      //this.tab = tab_no
      if (tab_no === 1)
        this.$router
          .push({
            name: "Condition",
            query: {
              searchname: this.$route.query.searchname,
              searchtype: this.$route.query.searchtype,
            },
          })
          .catch(() => {});
      else if (tab_no === 2)
        this.$router
          .push({
            name: "Ranking",
            query: {
              searchname: this.$route.query.searchname,
              searchtype: this.$route.query.searchtype,
            },
          })
          .catch(() => {});
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>