<template>
  <div>
    <locations />

    <div id="brs" class="page wrap-1200">
      <h3 class="subtitle">구매 내역</h3>
      <section class="adv mt-0">
        <!-- <h6 class="sub-title">피팅 광고</h6> -->
        <div class="row g-0">
          <div class="col-md-6">
            <div
              class="col adv-item adv-video"
              :class="{
                'has-item': fittingsaleview.adinfo.ad_mov_info.fit_ad_mov,
              }"
            >
              <video
                class="ratio align-self-center"
                controls
                autoplay
                muted
                v-if="fittingsaleview.adinfo.ad_mov_info.fit_ad_mov"
              >
                <source
                  v-if="fittingsaleview.adinfo.ad_mov_info.fit_ad_mov"
                  type="video/mp4"
                  :src="fittingsaleview.adinfo.ad_mov_info.fit_ad_mov | get_img"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div class="col-md-6 flex-wrap">
            <div
              class="col-12 adv-item adv-title justify-content-center mb-2"
              :class="{
                'has-item': fittingsaleview.adinfo.ad_title_info.fit_ad_img,
              }"
            >
              <img
                v-if="fittingsaleview.adinfo.ad_title_info.fit_ad_img"
                class="align-self-center"
                :src="fittingsaleview.adinfo.ad_title_info.fit_ad_img | get_img"
              />
            </div>
            <div class="row g-0 flex-grow-1">
              <div
                class="col adv-item adv-goods flex-sm-wrap"
                :class="{
                  'has-item': fittingsaleview.adinfo.ad_img1_info.fit_ad_img,
                }"
              >
                <img
                  v-if="fittingsaleview.adinfo.ad_img1_info.fit_ad_img"
                  :src="
                    fittingsaleview.adinfo.ad_img1_info.fit_ad_img | get_img
                  "
                />
                <p
                  class="goods-name"
                  v-if="fittingsaleview.adinfo.ad_img1_info.fit_ad_img"
                >
                  <span>{{
                    fittingsaleview.adinfo.ad_img1_info.fit_ad_title
                  }}</span>
                </p>
              </div>

              <div
                class="col adv-item adv-goods mx-0 mx-sm-2 flex-sm-wrap"
                :class="{
                  'has-item': fittingsaleview.adinfo.ad_img2_info.fit_ad_img,
                }"
              >
                <img
                  v-if="fittingsaleview.adinfo.ad_img2_info.fit_ad_img"
                  :src="
                    fittingsaleview.adinfo.ad_img2_info.fit_ad_img | get_img
                  "
                />
                <p
                  class="goods-name"
                  v-if="fittingsaleview.adinfo.ad_img2_info.fit_ad_img"
                >
                  <span>{{
                    fittingsaleview.adinfo.ad_img2_info.fit_ad_title
                  }}</span>
                </p>
              </div>
              <div
                class="col adv-item adv-goods flex-sm-wrap"
                :class="{
                  'has-item': fittingsaleview.adinfo.ad_img3_info.fit_ad_img,
                }"
              >
                <img
                  v-if="fittingsaleview.adinfo.ad_img3_info.fit_ad_img"
                  :src="
                    fittingsaleview.adinfo.ad_img3_info.fit_ad_img | get_img
                  "
                />
                <p
                  class="goods-name"
                  v-if="fittingsaleview.adinfo.ad_img3_info.fit_ad_img"
                >
                  <span>{{
                    fittingsaleview.adinfo.ad_img3_info.fit_ad_title
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <h6 class="sub-title">
          <span class="brand-name fw-bold">{{
            fittingsaleview.goodsinfo.fit_brand_nm
          }}</span
          >에서 추천하는
          <span class="text-green">고급형</span>
        </h6>
        <!-- <div class="card m-0 p-0 bg-transparent border-0 rounded-0"> -->
          <div class="row g-0 table-type header-col">
            <div class="col-sm-3">
              <div
                class="d-flex flex-fill justify-content-center h-100 bg-white"
              >
                <img
                  :src="fittingsaleview.goodsinfo.fit_goods_image1 | get_img"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-sm-9 d-flex flex-column align-items-stretch">
              <div class="card-body px-0 px-sm-3">
                <h4 class="card-title">
                  {{ fittingsaleview.goodsinfo.fit_goods_nm }}
                </h4>
                <div class="row g-0 row-cols-1 row-cols-sm-2">
                  <div
                    class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fs-6 fw-bold text-black"
                  >
                    매장명
                  </div>
                  <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                    <h5>
                      {{ fittingsaleview.shopname }}
                    </h5>
                  </div>

                  <div
                    class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fs-6 fw-bold text-black"
                  >
                    소비자가
                  </div>
                  <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                    <h5 class="text-price">
                      &#8361;
                      {{ fittingsaleview.answerinfo.fit_sale_price | comma }}
                    </h5>
                  </div>

                  <div
                    class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fs-6 fw-bold text-black"
                  >
                    구매일자
                  </div>
                  <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                    <span class="fs-6 text-date">
                      {{ fittingsaleview.fit_buy_date }}
                    </span>
                  </div>

                  <div
                    class="col-sm-4 mt-3 mt-sm-0 py-0 py-sm-2 border-top-sm fs-6 fw-bold text-black"
                  >
                    구매혜택
                  </div>
                  <div class="col-sm-8 py-0 py-sm-2 border-top-sm fs-6">
                    {{ fittingsaleview.goodsinfo.fit_goods_info }}
                    <!-- 미등록 -->
                  </div>
                </div>
              </div>
              <!-- //card-body -->
              <!-- <div
                      class="card-footer px-0 px-sm-3 py-0 bg-transparent border-0 rounded-0"
                    >
                      <div
                        class="btn-bottom-wrapper mt-2 mb-4 mt-sm-0 mb-sm-3"
                      >
                        <b-button
                          size="md"
                          variant="primary"
                          aria-label="제품구매"
                        >
                          제품구매
                        </b-button>
                      </div>
                    </div> -->
              <!-- //card-footer -->
            </div>
          </div>
        <!-- </div> -->
      </section>
      <!-- //기본 정보 등록 -->

      <section>
        <h6 class="sub-title">제품 상세 정보</h6>
        <div class="row g-0 table-type header-col">
          <img
            v-if="fittingsaleview.goodsinfo.fit_goods_image2"
            :src="fittingsaleview.goodsinfo.fit_goods_image2 | get_img"
            class="img-fluid"
          />
          <img
            v-if="fittingsaleview.goodsinfo.fit_goods_image3"
            :src="fittingsaleview.goodsinfo.fit_goods_image3 | get_img"
            class="img-fluid"
          />
        </div>
      </section>

      <section class="reg-spec">
        <h6 class="sub-title">스펙</h6>
        <b-table-simple responsive table-class="border-0">
          <b-thead>
            <b-tr>
              <b-th sticky-column>클럽</b-th>
              <b-th
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clubnotype' + idx"
              >
                {{
                  fittingsaleview.clubtypelist.find(
                    (v) => v.clubtype_code == val.fit_club_nm
                  ).clubtype_code_name
                }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-th sticky-column>로프트(&deg;)</b-th>
              <b-td
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clubloft' + idx"
              >
                {{ val.fit_club_loft }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>라이(&deg;)</b-th>
              <b-td
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clublie' + idx"
              >
                {{ val.fit_club_lie }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>헤드볼륨(㎤)</b-th>
              <b-td
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clubheadvolume' + idx"
              >
                {{ val.fit_club_headvolume }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>FP(mm)</b-th>
              <b-td
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clubfp' + idx"
                >{{ val.fit_club_fp }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>길이(Inch)</b-th>
              <b-td
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clublength' + idx"
              >
                {{ val.fit_club_length }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column rowspan="5">
                <div class="sticky-column-rowspan">
                  <div>밸런스</div>
                  <div class="flex-column">
                    <div v-if="fittingsaleview.answerinfo.fit_balance1 > 0">
                      {{
                        fittingsaleview.answerinfo.fit_balance1 | get_balance
                      }}
                    </div>
                    <div v-if="fittingsaleview.answerinfo.fit_balance2 > 0">
                      {{
                        fittingsaleview.answerinfo.fit_balance2 | get_balance
                      }}
                    </div>
                    <div v-if="fittingsaleview.answerinfo.fit_balance3 > 0">
                      {{
                        fittingsaleview.answerinfo.fit_balance3 | get_balance
                      }}
                    </div>
                    <div v-if="fittingsaleview.answerinfo.fit_balance4 > 0">
                      {{
                        fittingsaleview.answerinfo.fit_balance4 | get_balance
                      }}
                    </div>
                    <div
                      class="py-2 pe-2"
                      v-if="fittingsaleview.answerinfo.fit_balance5 > 0"
                    >
                      {{
                        fittingsaleview.answerinfo.fit_balance5 | get_balance
                      }}
                    </div>
                  </div>
                </div>
              </b-th>
              <template
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
              >
                <b-td
                  :key="'p_clubflextitle1' + idx"
                  v-if="val.fit_club_balance1"
                >
                  {{ val.fit_club_balance1 }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <template
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
              >
                <b-td
                  :key="'p_clubflextitle2' + idx"
                  v-if="val.fit_club_balance2"
                >
                  {{ val.fit_club_balance2 }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <template
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
              >
                <b-td
                  :key="'p_clubflextitle3' + idx"
                  v-if="val.fit_club_balance3"
                >
                  {{ val.fit_club_balance3 }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <template
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
              >
                <b-td
                  :key="'p_clubflextitle4' + idx"
                  v-if="val.fit_club_balance4"
                >
                  {{ val.fit_club_balance4 }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <template
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
              >
                <b-td
                  :key="'p_clubflextitle5' + idx"
                  v-if="val.fit_club_balance5"
                >
                  {{ val.fit_club_balance5 }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <b-th sticky-column class="border-0">총중량(g)</b-th>
              <b-td
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clubweight' + idx"
              >
                {{ val.fit_club_weight }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </section>
      <!-- 스펙 등록 -->

      <section class="reg-etc">
        <div class="row gx-4 gy-5 border-0">
          <div class="col-lg-6 d-flex flex-column">
            <h6 class="sub-title">샤프트</h6>
            <div
              class="card h-100"
              v-if="fittingsaleview.shaftinfo.fit_goods_shaft_pk"
            >
              <div class="card-body d-flex flex-column p-0">
                <p class="card-title">
                  {{ fittingsaleview.shaftinfo.fit_goods_shaft_nm }}
                  /
                  {{
                    fittingsaleview.shaftinfo.fit_shaft_material
                      | get_shaft_material
                  }}
                </p>
                <div
                  class="d-flex justify-content-center align-items-center h-100 py-3"
                >
                  <img
                    :src="
                      fittingsaleview.shaftinfo.fit_goods_shaft_image1 | get_img
                    "
                    class="img-fluid"
                    alt="샤트프"
                  />
                </div>
              </div>
              <div class="card-footer">
                <table class="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">강도</th>
                      <th scope="col">무게(g)</th>
                      <th scope="col">토크(˚)</th>
                      <th scope="col">킥포인트</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{
                          fittingsaleview.shaftinfo.fit_goods_shaft_balance
                            | get_balance
                        }}
                      </td>
                      <td>
                        {{ fittingsaleview.shaftinfo.fit_goods_shaft_weight }}
                      </td>
                      <td>
                        {{ fittingsaleview.shaftinfo.fit_goods_shaft_torque }}
                      </td>
                      <td>
                        {{
                          fittingsaleview.shaftinfo.fit_goods_shaft_kickpoint
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="text-center" v-else>데이터가 없습니다.</div>
          </div>
          <div class="col-lg-6 d-flex flex-column">
            <h6 class="sub-title">그립</h6>
            <div class="card h-100" v-if="fittingsaleview.gripinfo">
              <div class="card-body d-flex flex-column p-0">
                <p class="card-title">
                  {{ fittingsaleview.gripinfo.fit_goods_grip_nm }}}
                </p>
                <div
                  class="d-flex justify-content-center align-items-center h-100 py-3"
                >
                  <img
                    :src="
                      fittingsaleview.gripinfo.fit_goods_grip_image1 | get_img
                    "
                    class="img-fluid"
                    alt="그립"
                  />
                </div>
              </div>
              <div class="card-footer">
                <table class="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">규격</th>
                      <th scope="col">무게(g)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ fittingsaleview.gripinfo.fit_goods_grip_standard }}
                      </td>
                      <td>
                        {{ fittingsaleview.gripinfo.fit_goods_grip_weight }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="text-center" v-else>데이터가 없습니다.</div>
          </div>
        </div>
      </section>
      <!-- 샤프트 & 그립 등록 -->

      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="outline-primary"
          aria-label="목록"
          @click="
            $router.push({
              name: 'FittingList',
            })
          "
          >목록
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import locations from "@/components/Location";
import ax from "@/api/fitting";
import "@/filter/common";
export default {
  name: "FittingSaleView",
  data() {
    return {
      fitansno: this.$route.params.fitansno,
      fittingsaleview: {
        adinfo: {
          ad_img1_info: {},
          ad_img2_info: {},
          ad_img3_info: {},
          ad_mov_info: {},
          ad_title_info: {},
        },
        goodsinfo: {},
        memberinfo: {},
        answerinfo: [],
        gripinfo: {},
        shaftinfo: {},
        clubtypelist: [],
      },
    };
  },
  components: {
    locations,
  },
  created() {
    console.log(
      Array.from(
        { length: 20 },
        (x, i) => this.$moment(new Date()).format("YYYY") - i
      )
    );
  },
  methods: {
    get_saleview() {
      ax.get_saleview(this.fitansno, (flag, data) => {
        if (flag) {
          this.fittingsaleview = data.fittingsaleview;
          console.log(this.fittingsaleview);
        } else {
          alert(data.message);
        }
      });
    },
  },
  created() {
    this.get_saleview();
  },
};
</script>